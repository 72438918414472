import React from 'react';
import Aux from '../../hoc/Aux';
import './LoginG2F.css'
import Logo from '../../assets/images/transparent_red.png';

const contactUs = (props) => {
    return(
        <Aux>
            <span className="modal_close" onClick={props.closeModal} style={{marginTop:"0em"}}>X</span>
            <div style={{backgroundColor: "white", width:"100%"}}>
                
                <h2 className="span4 logoJumbotron subhead tex-center title"> <img src={Logo} alt="G2FLogo"/>Contact Us</h2>
                <div style={{marginLeft:"20px", marginRight:"50px", fontWeight:"bold", fontSize:"15px", marginBottom:"2em", marginTop:"2em"}}>
                    <p>For software support, reporting of bugs or installation issues, please contact: <br/>support@geo2flow.com</p>
                    <br/>
                    <p>For sales questions and quotations, please contact: <br/>sales@geo2flow.com</p>
                    <br/>
                    <p>For consulting inquiries please contact: <br/>sales@geo2flow.com</p>
                    <br/>
                    <p>Office Hours: 10:00am - Midnight CST USA <br/>Sunday-Friday</p>
                    <br/>
                    <p>Office phone: +1-210-481-6339<br/>Office fax: +1-210-855-9203</p>
                    <br/>
                </div>
            </div>
        </Aux>
)};

export default contactUs;