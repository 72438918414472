import React from 'react';
import Aux from '../../hoc/Aux';

const copyrightFooter = (props) => {
    const d = new Date();
    const privacyStatement = "https://amplify-amplifycustomauth-dev-145228-deployment.s3.amazonaws.com/Privacy+Statement.pdf"

    return(
        <Aux>
            <footer className="footer">
                Geo2Flow ® <br/>
                © 2005 - {d.getFullYear()} O'Meara Consulting, Inc. <br/>
                <a href={privacyStatement}>Geo2Flow Privacy Statement</a>
            </footer>
        </Aux>
)};

export default copyrightFooter;