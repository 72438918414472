import React, { Component } from 'react';
import './Dropdown.css';
import {
  S3Client,
  ListObjectsV2Command,
} from "@aws-sdk/client-s3";

const creds = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
};
const client = new S3Client({region:'us-east-1', credentials: creds});

//append the key to this for the full url
const s3BaseUrl = "https://g2fbrochures.s3.amazonaws.com/" 

class DropdownBrochure extends Component {
constructor(){
 super();

 this.state = {
       displayMenu: false,
       brochureTitles: ["Loading..."],
       brochureUrls: ["Loading..."]
      };

  this.showDropdownMenu = this.showDropdownMenu.bind(this);
  this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
};

componentDidMount = async () => {
  //get the brochure titles and links
  const command = new ListObjectsV2Command({
    Bucket: "g2fbrochures",
  });
  const response = await client.send(command);

  //Remove the loading title/url as we now have our objects
  this.setState({ brochureTitles: [] })
  this.setState({ brochureUrls: [] })

  for(let key of response.Contents) {
    let fileName = key["Key"]
    //split so title is everything before the first period (remove the extension)
    this.setState({ brochureTitles: [...this.state.brochureTitles, fileName.split('.')[0]] }) 
    this.setState({ brochureUrls: [...this.state.brochureUrls, s3BaseUrl.concat(fileName)] })
  }
}

renderBrochureDropdown() {
  return (
    <ul className="dropdownUl" style={{right: "0", left:"min(15vw, 150px)"}}>
      {
        this.state.brochureTitles.map((title, index) => {
          return <li key={title}><a href={this.state.brochureUrls[index]} target="_blank" rel="noopener noreferrer">{title}</a></li>
        })
      }
    </ul>
  )
}

showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
    document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }

  render() {
    return (
        <div  className="dropdown">
         <div className="button2" onClick={this.showDropdownMenu}>Download Geo2Flow Brochures <i className="arrow down"></i></div>

          { this.state.displayMenu ? (
            <div>
              {this.renderBrochureDropdown()}
            </div>
        ):
        (
          null
        )
        }
       </div>
    );
  }
}

export default DropdownBrochure;