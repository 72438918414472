import React from 'react';
import './Navbar.css';

const navbar = props => {

    return(
    <div className="myNavbar">
        <ul>
            <li onClick={props.contact}><p>Contact Us</p></li>
        </ul>
    </div>
)};

export default navbar;
