import React from 'react';
import Logo from '../../../assets/images/transparent_red.png';

const notFound = () => (
    <div>
    <h1 className="logoJumbotron subhead" 
        style={{fontSize:"48px", width:"70%", marginLeft:"auto", marginRight:"auto", 
        marginTop: "0px", textAlign:"center", padding:"0"}}>
            <img src={Logo} alt="G2FLogo"/>404 Page Not Found
    </h1>
    <h3 style={{width:"70%", marginLeft:"auto", marginRight:"auto", 
        marginTop: "0px", textAlign:"center", padding:"0"}}>
            The page you're looking for does not exist
    </h3>
    </div>
)

export default notFound;