import React, { Component } from 'react';
import './Dropdown.css';
import {
  S3Client,
  ListObjectsV2Command,
} from "@aws-sdk/client-s3";

const creds = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
};
const client = new S3Client({region:'us-east-1', credentials: creds});

//append the key to this for the full url
const s3BaseUrl = "https://g2fvideos.s3.amazonaws.com/" 

class Dropdown extends Component {
constructor(){
 super();

 this.state = {
       displayMenu: false,
       videoTitles: ["Loading..."],
       videoUrls: ["Loading..."]
     };

  this.showDropdownMenu = this.showDropdownMenu.bind(this);
  this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
};

componentDidMount = async () => {
  //get the video titles and links
  const command = new ListObjectsV2Command({
    Bucket: "g2fvideos",
  });
  const response = await client.send(command);

  //Remove the loading title/url as we now have our objects
  this.setState({ videoTitles: [] })
  this.setState({ videoUrls: [] })

  for(let key of response.Contents) {
    let fileName = key["Key"]
    //split so title is everything before the first period (remove the extension)
    this.setState({ videoTitles: [...this.state.videoTitles, fileName.split(".")[0]] })
    this.setState({ videoUrls: [...this.state.videoUrls, s3BaseUrl.concat(fileName)] })
  }
}

renderVideoDropdown() {
  return (
    <ul className="dropdownUl">
      {
        this.state.videoTitles.map((title, index) => {
          return <li key={title}><a href={this.state.videoUrls[index]} target="_blank" rel="noopener noreferrer">{title}</a></li>
        })
      }
    </ul>
  )
}

showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
    document.addEventListener('click', this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });

  }

  render() {
    return (
        <div  className="dropdown">
         <div className="button1" onClick={this.showDropdownMenu}>Watch Videos <i className="arrow down"></i></div>

          { this.state.displayMenu ? (
            <div>
              {this.renderVideoDropdown()}
            </div>
        ):
        (
          null
        )
        }
       </div>
    );
  }
}

export default Dropdown;