import React from 'react';
import './Modal.css';
import Backdrop from '../Backdrop/Backdrop';
import Aux from '../../../hoc/Aux';

const modal = (props) => (
    <Aux>
        <Backdrop show={props.show} clicked={props.modalClosed}/>
        <div className="Modal" 
        style={{transform: props.show ? props.height : 'translateY(-100vh)',
        opacity: props.show ? '1' : '0', zIndex: props.show ? '9998' : '0'}}>
            {props.children}
        </div>
    </Aux>
);

export default modal;