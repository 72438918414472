import React, { Component } from 'react';
import './assets/style/App2.css';
import './assets/style/bootstrap/css/bootstrap.css';
import './assets/style/docs.css';
import HomePage from './container/HomePage';
import { BrowserRouter, Switch } from 'react-router-dom';
import NotFound from './components/G2F/Pages/NotFound';
import { Route } from 'react-router-dom';


class App extends Component {
  state = {
    userGroup: "none"
  }

  render() {
    return (
      <BrowserRouter>
      <div className='App'>
        <Switch>
          <Route path="/" exact component={() => <HomePage/>}/>
          <Route component={()=> <NotFound/>}/>
        </Switch>
      </div>
      </BrowserRouter>
    );
  }
}

export default App;
