import React, { Component } from "react";
import Navbar from '../components/Navbar/Navbar';
import TopHalf from '../components/G2F/Pages/TopHalf';
import BottomHalf from '../components/G2F/Pages/BottomHalf';
import Modal from '../components/UI/Modal/Modal';
import ContactUs from '../components/G2F/ContactUs';


class HomePage extends Component {

    state = {
        displayContactModal: false,
        anyModalActive: false
    }

      displayContactModalOn = () => {
        this.setState({
            displayContactModal: true,
            anyModalActive: true
          });
      }

      displayContactModalOff = () => {
        this.setState({
          displayContactModal: false
        });
      }

      closeIfModalOn = () => {
        if(this.state.displayContactModal === true) {
            this.displayContactModalOff();
          }
        this.setState({
          anyModalActive: false
        });
      }


    render() {
        return(
        <div>
            <Navbar contact={this.displayContactModalOn} isHome={true}/>
            <Modal show={this.state.displayContactModal} height='translateY(0)'>
                <ContactUs closeModal={this.closeIfModalOn}/>
            </Modal>
            <div id="main" style={{width: "70%", marginLeft: "auto", marginRight: "auto"}}  onClick={this.closeIfModalOn}>
              <TopHalf show={this.state.anyModalActive}/>
              <BottomHalf/>
            </div>
        </div>
        )
        
    };
}

export default HomePage;