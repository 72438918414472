import React from 'react';
import Dropdown from '../../UI/Dropdown/Dropdown';
import DropdownBrochure from '../../UI/Dropdown/DropdownBrochure'

const subTitle1 = "Powerful oil and gas software for faster and more accurate";
const subTitle2 = "saturation, compartment, and permeability modeling."

const tophalf = props => {
    return (
        <div className="myJumbotron" style={{opacity: props.show ? '0.4' : '1'}}>
            <div className="container">
                <h1 className="titleFont">Geo2Flow</h1>
                <p className="subTitle">{subTitle1}</p>
                <p className="subTitle">{subTitle2}</p>
                <div className="mb-2">
                    <DropdownBrochure/>
                    <br/>
                    <br/>
                    <Dropdown/>
                </div>
            </div>
        </div>
    )
};

export default tophalf;