import React from 'react';
import mess from '../../../assets/images/compare_sat.jpg';
import match from '../../../assets/images/sat_match.jpg';
import CopyrightFooter from '../CopyrightFooter'
import '../../../assets/style/App2.css'

const bestPractices = "Geo2Flow uses patented technology for identifying reservoir compartments, for calculating 3D permeabilities that are consistent with saturation logs, and for ensuring that 3D saturations match their corresponding logs exactly."
const bottomText1 = "Do your 3D saturations match your well logs? With which figure above do you want to start your history match?"
const bottomText2 = "Check the quality of the match with the Geo2Flow ToolBox.";


const bottomhalf = props => (
    <div className="container">
              <div className="marketing">
                  <h1 className="bottomTitle">Best Practices</h1>
                  <div className="row">
                      <div style={{maxWidth: "900px", marginRight:"auto", marginLeft:"auto"}}>
                        <p className="subsubTitle">{bestPractices}</p>
                      </div>
                  </div>
                  <div className="row">
                    <div className="plotImages">
                      <figure style={{marginInlineEnd:"10px"}}>
                        <img src={mess} alt="non-Geo2Flow mess"/>
                        <figcaption className="caption">A typical non-Geo2Flow mess</figcaption>
                      </figure>
                      <figure style={{marginInlineStart:"10px"}}>
                        <img src={match} alt="a Geo2Flow match"/>
                        <figcaption className="caption">Geo2Flow's excellent match</figcaption>
                      </figure>
                      
                    </div>
                  </div>
                  <div style={{maxWidth: "950px", marginRight:"auto", marginLeft:"auto"}}>
                    <h4 className="bottomText">{bottomText1}</h4>
                    <h4 className="bottomText">{bottomText2}</h4>
                    <p className="queriesFont" style={{fontWeight:"400"}}>For all queries, contact sales@geo2flow.com</p>
                  </div>
                  <CopyrightFooter/>
        </div>
    </div>
);
export default bottomhalf;